import React from "react"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import HTML from "../components/HTML"
import PersonalDataForm from "../components/personalDataForm/PersonalDataForm"

function CCPA(props) {
    const { pageContext } = props
    const { pageContent,lead_url,site_id, dealerInfo } = pageContext
    const socialThumbnail = dealerInfo.socialThumbnail ? dealerInfo.socialThumbnail : ''
    
    const document = typeof window !== 'undefined' && window.document

    let components = [
        <PersonalDataForm data={pageContent.PersonalDataForm} lead_url={lead_url} site_id={site_id}  />,
        <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} socialThumbnail={socialThumbnail}/>
    ]

    if(pageContent.hasOwnProperty("ArrayRawHTML")) {
        const { arrayRawHTML } = pageContent.ArrayRawHTML
        const rawComponents = arrayRawHTML.map(element => {
            return <HTML data={{
                order: Number(element.order),
                visible: element.visible,
                rawHTML: element.rawHtml
            }} key={"HTML"} />
        })
        components.push(...rawComponents)
    }

    let sortedComponents = components.sort((a, b) =>
        parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
    ) // sorts the components based on json data
    // filters out the components that are not visible
    let visibleComponents = sortedComponents.filter(component => {
        return component.props.data.visible === true
    })

    return (
        <Layout>
            { visibleComponents}
        </Layout>
    )
}

export default CCPA
